import React from 'react';
import { Header, Image, Container } from 'semantic-ui-react';
import FishermanLogo from '../data/logos/FishermanRedIconLogo.png';
import Layout from '../layouts/SiteLayout';

export default () => (
  <Layout>
    <Container style={{ marginTop: '10vh' }} textAlign="center">
      <Header as="h2">Sorry, this page could be not found!</Header>
      <Image
        style={{ position: 'absolute', marginLeft: '50%', left: '-75px' }}
        src={FishermanLogo}
        size="small"
      />
    </Container>
  </Layout>
);
